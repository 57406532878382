exports.components = {
  "component---src-components-browse-blog-posts-jsx": () => import("./../../../src/components/browse-blog-posts.jsx" /* webpackChunkName: "component---src-components-browse-blog-posts-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-geocoding-in-excel-index-mdx": () => import("./../../../src/pages/blog/GeocodingInExcel/index.mdx" /* webpackChunkName: "component---src-pages-blog-geocoding-in-excel-index-mdx" */),
  "component---src-pages-blog-gpt-3-index-mdx": () => import("./../../../src/pages/blog/GPT3/Index.mdx" /* webpackChunkName: "component---src-pages-blog-gpt-3-index-mdx" */),
  "component---src-pages-blog-happy-2022-mdx": () => import("./../../../src/pages/blog/happy2022.mdx" /* webpackChunkName: "component---src-pages-blog-happy-2022-mdx" */),
  "component---src-pages-blog-qr-code-qr-code-in-excel-mdx": () => import("./../../../src/pages/blog/QRCode/QRCodeInExcel.mdx" /* webpackChunkName: "component---src-pages-blog-qr-code-qr-code-in-excel-mdx" */),
  "component---src-pages-blog-simple-sales-forecast-index-mdx": () => import("./../../../src/pages/blog/SimpleSalesForecast/index.mdx" /* webpackChunkName: "component---src-pages-blog-simple-sales-forecast-index-mdx" */),
  "component---src-pages-blog-websiteonline-mdx": () => import("./../../../src/pages/blog/websiteonline.mdx" /* webpackChunkName: "component---src-pages-blog-websiteonline-mdx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-resume-jsx": () => import("./../../../src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */)
}

