module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","fr"],"defaultLanguage":"en","siteUrl":"http://www.stephanecatoire.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false},"pages":[{"matchPath":"/preview","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"posts":"D:\\Clients\\StephaneCatoire\\Gastby\\scatoire-perso\\src\\components\\blog-post-layout.jsx","default":"D:\\Clients\\StephaneCatoire\\Gastby\\scatoire-perso\\src\\components\\blog-post-layout.jsx"},"gatsbyRemarkPlugins":[{"resolve":"D:/Clients/StephaneCatoire/Gastby/scatoire-perso/node_modules/gatsby-remark-copy-linked-files","id":"1b9ede79-ae42-512d-85d9-8de720ad0487","name":"gatsby-remark-copy-linked-files","version":"5.9.0","modulePath":"D:\\Clients\\StephaneCatoire\\Gastby\\scatoire-perso\\node_modules\\gatsby-remark-copy-linked-files\\index.js","pluginOptions":{"plugins":[],"ignoreFileExtensions":["png","jpg","jpeg"]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"D:/Clients/StephaneCatoire/Gastby/scatoire-perso/node_modules/gatsby-remark-images","id":"a5be57c9-0b6d-5572-8be9-8d0e9e4b0f0b","name":"gatsby-remark-images","version":"6.8.0","modulePath":"D:\\Clients\\StephaneCatoire\\Gastby\\scatoire-perso\\node_modules\\gatsby-remark-images\\index.js","pluginOptions":{"plugins":[],"maxwidth":1920},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"D:/Clients/StephaneCatoire/Gastby/scatoire-perso/node_modules/gatsby-remark-relative-images","id":"46bd1896-257f-5076-b73b-7e4836aab9a7","name":"gatsby-remark-relative-images","version":"2.0.2","modulePath":"D:\\Clients\\StephaneCatoire\\Gastby\\scatoire-perso\\node_modules\\gatsby-remark-relative-images\\dist\\index.js","module":{"defaultPluginOptions":{"staticFolderName":"static","include":[],"exclude":[]}},"pluginOptions":{"plugins":[]},"nodeAPIs":["onCreateNode"],"browserAPIs":[],"ssrAPIs":[]}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"D:\\Clients\\StephaneCatoire\\Gastby\\scatoire-perso","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxwidth":1920},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-S292ZMEX74"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
